import {
    ComboBox,
    DefaultButton,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    StackItem,
    TextField,
    Toggle,
} from '@fluentui/react';
import { Field, ValidationBar } from 'components';
import { useValidation } from 'hooks';
import useProcedures from 'hooks/state/useProcedures';
import { IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProcedureCategoryOptions } from 'state/slices/procedures/procedures.selectors';
import { getProcedureCategories } from 'state/slices/procedures/procedures.slice';
import { convertDateTimeToBeforeMidnight, convertDateTimeToMidnight } from 'utils/dateOnly';

export default function ProcedurePanel() {
    const {
        cleanupSelectedProcedure,
        selectedProcedure,
        updateSelectedProcedureProp,
        updateProcedure,
        saving,
        isAdding,
        addProcedure,
    } = useProcedures();

    const procedureCategoryOptions = useSelector(selectProcedureCategoryOptions)

    const dispatch = useDispatch();

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Code',
            validation: ['required'],
            value: selectedProcedure?.code,
        },
        {
            fieldName: 'Display Name',
            validation: ['required'],
            value: selectedProcedure?.displayName,
        },
        {
            fieldName: 'Description',
            validation: ['required'],
            value: selectedProcedure?.description,
        },
    ];

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onAddOrSave);

    const isOpen = !!selectedProcedure;

    useEffect(() => {
        if (isOpen)
            dispatch(getProcedureCategories());
    }, [dispatch, isOpen])

    function _onDismiss() {
        cleanupSelectedProcedure();
        cleanupErrors();
    }

    function onAddOrSave() {
        if (selectedProcedure) {
            cleanupErrors();
            if (!isAdding) {
                updateProcedure(selectedProcedure);
            } else {
                addProcedure(selectedProcedure);
            }
        }
    }

    function toggleIsDeleted() {
        updateSelectedProcedureProp('isDeleted', !selectedProcedure?.isDeleted);
    }

    return (
        <Panel
            isOpen={isOpen}
            isFooterAtBottom
            onDismiss={_onDismiss}
            type={PanelType.medium}
            headerText={isAdding ? `Add Procedure` : `Edit Procedure`}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add' : 'Save'}
                            onClick={onSubmit}
                            disabled={saving === LoadingStatus.Pending}
                        />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <Stack grow horizontalAlign="end">
                            {saving === LoadingStatus.Pending && (
                                <Spinner label={isAdding ? 'Adding...' : 'Saving...'} labelPosition="left" />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack grow horizontal horizontalAlign="end">
                {!isAdding && (
                    <Toggle label="Deactivated" checked={selectedProcedure?.isDeleted} inlineLabel onClick={toggleIsDeleted} />
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} grow horizontal>
                    <StackItem>
                        <TextField
                            styles={{ root: { maxWidth: 60 } }}
                            label="Code"
                            value={selectedProcedure?.code}
                            onChange={(ev, value) => updateSelectedProcedureProp('code', value)}
                            disabled={!isAdding}
                            maxLength={5}
                            required
                        />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            label="Display Name"
                            value={selectedProcedure?.displayName}
                            onChange={(ev, value) => updateSelectedProcedureProp('displayName', value)}
                            maxLength={200}
                            required
                        />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            label="Description"
                            value={selectedProcedure?.description}
                            onChange={(ev, value) => updateSelectedProcedureProp('description', value)}
                            maxLength={200}
                            required
                        />
                    </StackItem>
                </Stack>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <StackItem grow>
                        <Field.Date
                            label="Effective Date"
                            value={selectedProcedure?.effectiveDate ? selectedProcedure?.effectiveDate : ''}
                            onChange={(ev, date) => updateSelectedProcedureProp('effectiveDate', convertDateTimeToMidnight(date))}
                            hasDatePicker
                        />
                    </StackItem>
                    <StackItem grow>
                        <Field.Date
                            label="End Date"
                            value={selectedProcedure?.endDate ? selectedProcedure?.endDate : ''}
                            onChange={(ev, date) => updateSelectedProcedureProp('endDate', convertDateTimeToBeforeMidnight(date))}
                            hasDatePicker
                        />
                    </StackItem>
                </Stack>
                <StackItem>
                    <ComboBox
                        label="Category"
                        options={procedureCategoryOptions}
                        placeholder="(Select category)"
                        selectedKey={selectedProcedure?.categoryId as string}
                        onChange={(ev, option) => updateSelectedProcedureProp('categoryId', option?.key)}
                    />
                </StackItem>
            </Stack>
        </Panel>
    );
}
