import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import {
    IComboBoxProps,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    SelectableOptionMenuItemType,
    ISelectableOption,
    IComboBoxStyles,
    useTheme,
    Text,
    VirtualizedComboBox,
} from '@fluentui/react';
import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import { useSelector } from 'hooks';

export interface ISearchComboFieldProps extends IComboBoxProps {
    maxResults?: number;
    threshold?: number;
    onSearch?: (search: string | undefined) => void;
    virutalzied?: boolean;
    altColor?: boolean;
}

const SearchComboField = ({
    selectedKey,
    options,
    label,
    placeholder = '(Select)',
    maxResults = 35,
    onChange,
    multiSelect,
    threshold = 0.1,
    onSearch,
    virutalzied,
    altColor,
    ...props
}: ISearchComboFieldProps): JSX.Element => {
    const theme = useTheme();
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);

    const comboBoxRef = useRef<IComboBox>(null);
    const openMenu = useCallback(() => {
        comboBoxRef.current?.focus(true);
    }, []);

    const hasMultipleSelectedKeys = Array.isArray(selectedKey);
    const filteredOptions = useMemo(
        () =>
            options.filter((option) => {
                return hasMultipleSelectedKeys
                    ? (selectedKey as (number | string)[]).indexOf(option.key) === -1
                    : selectedKey !== option.key;
            }),
        [options, selectedKey],
    );

    const [comboBoxOptionResults, setComboBoxOptionResults] = useState<IComboBoxOption[]>([]);
    const [search, setSearch] = useState<string>('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fuse = useMemo(() => new Fuse([] as IComboBoxOption[], { threshold, keys: ['text'] }), []);

    const fuseSearch = () => {
        const results = fuse.search(search);
        const mappedResults = results
            .sort((curr, prev) => {
                return (curr.score as number) + (prev.score as number);
            })
            .map((proc) => proc.item);
        setComboBoxOptionResults(mappedResults);
    };

    const currentlySelectedEntities = options.filter((o) => {
        if (hasMultipleSelectedKeys) {
            return (selectedKey as (string | number)[]).indexOf(o.key) > -1;
        } else {
            return o.key === selectedKey;
        }
    });

    const negotiatedOptions = search ? comboBoxOptionResults : filteredOptions;
    const resultOptions =
        negotiatedOptions.length >= maxResults ? [...negotiatedOptions.slice(0, maxResults)] : negotiatedOptions;

    const emptyOption = !multiSelect
        ? [
              {
                  key: '',
                  text: placeholder ? placeholder : '(Select)',
              },
          ]
        : [];

    const allOptions = selectedKey
        ? [
              {
                  key: 'header1',
                  text: 'Currently Selected',
                  itemType: SelectableOptionMenuItemType.Header,
              },
              {
                  key: 'divider3',
                  text: '',
                  itemType: SelectableOptionMenuItemType.Divider,
              },
              ...currentlySelectedEntities.map((entity) => {
                  return { ...entity };
              }),
              {
                  key: 'divider2',
                  text: '',
                  itemType: SelectableOptionMenuItemType.Divider,
              },
              {
                  key: 'header2',
                  text: 'Options',
                  itemType: SelectableOptionMenuItemType.Header,
              },
              {
                  key: 'divider1',
                  text: '',
                  itemType: SelectableOptionMenuItemType.Divider,
              },
              ...emptyOption,
              ...resultOptions,
          ]
        : [...emptyOption, ...resultOptions];

    useEffect(() => {
        if (!isEqual(options, comboBoxOptionResults)) {
            fuse.setCollection(filteredOptions);
            fuseSearch();
        }
        return () => {
            setComboBoxOptionResults([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredOptions]);

    useEffect(() => {
        fuseSearch();
        if (onSearch) onSearch(search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const updateOptions = (search: string) => {
        setSearch(search.trim().toLowerCase());
        fuseSearch();
    };
    const onRenderOption = (props: ISelectableOption | undefined) => {
        if (props) {
            if (props.key === '') return <Text style={{ color: theme.palette.neutralSecondaryAlt }}>{props?.text}</Text>;
            switch (props.itemType) {
                case SelectableOptionMenuItemType.Header:
                case SelectableOptionMenuItemType.Divider:
                    return <span>{props.text}</span>;
                default:
                    return <Text>{props.text}</Text>;
            }
        }
        return null;
    };

    const getStyles = (value: unknown | undefined): Partial<IComboBoxStyles> => ({
        optionsContainerWrapper: {
            backgroundColor: selectedTheme === 'dark' && altColor ? 'rgb(52, 52, 52)' : '',
        },
        input: {
            color: value === '' || value === null || value === undefined ? theme?.palette.neutralSecondary : theme?.palette.black,
        },
        callout: {
            // maxHeight: '50vh',
            selectors: {
                '.ms-Callout-main': {
                    maxHeight: '50vh !important',
                },
            },
        },
    });

    const ComboBoxComponent = virutalzied ? VirtualizedComboBox : ComboBox;

    return (
        <ComboBoxComponent
            {...props}
            componentRef={comboBoxRef}
            label={label}
            placeholder={placeholder}
            selectedKey={selectedKey}
            multiSelect={multiSelect}
            autoComplete="on"
            allowFreeform
            styles={{ ...getStyles(selectedKey), ...props.styles }}
            options={[...allOptions]}
            onInput={(e) => {
                const value = (e.target as unknown as { value: string }).value;
                updateOptions(value);
                openMenu();
            }}
            onBlur={() => {
                setSearch('');
            }}
            autoCorrect="on"
            onRenderOption={(props) => onRenderOption(props)}
            onChange={(e, option, index, value) => {
                //Unless the user is clicking away, we want the most relevant answer from the result list.
                const newOption = e.type !== 'blur' ? (resultOptions.length && !option ? resultOptions[0] : option) : undefined;
                if (newOption) {
                    setSearch('');
                    if (onChange) {
                        onChange(e, newOption, index, value);
                    }
                }
            }}
        />
    );
};

export default SearchComboField;
