import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    Dictionary,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import IProcedure from 'api/models/procedure.model';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { PlatformApi } from 'api';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';
import { isEqual } from 'lodash';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { IProcedureCategory } from 'api/models/procedure-category.model';

const initialState: ProceduresState = {
    procedures: {},
    procedureCategories: [],
    loading: LoadingStatus.Idle,
    loadingSelectedProcedure: LoadingStatus.Idle,
    loadingProcedureCategories: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    showHistory: false,
    isAdding: false,
};

export const getProcedureCategories = createAsyncThunk<IProcedureCategory[], undefined, { rejectValue: string }>(
    'procedureCategories/getProcedureCategories',
    async (_, { rejectWithValue }) => {
        try {
            const { data: procedureCategories } = await PlatformApi.getProcedureCategories();
            return procedureCategories;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);


export const getProcedures = createAsyncThunk<
    Dictionary<IProcedure>,
    undefined,
    { rejectValue: string }
>('procedures/getProcedures', async (_, { rejectWithValue }) => {
    try {
        const { data: procedures } = await PlatformApi.getAllProcedures();
        return procedures;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getProcedureById = createAsyncThunk<
    IProcedure,
    { procedureId: string },
    { rejectValue: string }
>('procedures/getProcedureById', async ({ procedureId }, { rejectWithValue }) => {
    try {
        const { data: Procedure } = await PlatformApi.getProcedureById(procedureId);
        return Procedure;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updateProcedure = createAsyncThunk<
    IProcedure,
    { procedure: IProcedure },
    { rejectValue: string }
>('procedures/updateProcedure', async ({ procedure }, { rejectWithValue }) => {
    try {
        const { data: updatedProcedure } = await PlatformApi.updateProcedure(procedure);
        return updatedProcedure;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updateProcedures = createAsyncThunk<
    IProcedure[],
    { procedures: IProcedure[] },
    { rejectValue: string }
>('procedures/updateProcedures', async ({ procedures }, { rejectWithValue }) => {
    try {
        const requests = procedures.map((procedure) => PlatformApi.updateProcedure(procedure));
        const response = await axios.all(requests);
        return response.map((r) => r.data);
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const addProcedure = createAsyncThunk<
    IProcedure,
    { procedure: IProcedure },
    { rejectValue: string }
>('procedures/addProcedure', async ({ procedure }, { rejectWithValue }) => {
    try {
        const { data: addedProcedure } = await PlatformApi.createProcedure(procedure);
        return addedProcedure;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

let timer: NodeJS.Timeout | null = null;
let proceduresToUpdate: IProcedure[] = [];

export const autoUpdateProcedure =
    (procedure: IProcedure) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>): void => {
        const indexOfProcedure = proceduresToUpdate.findIndex((p) => p.id === procedure.id);
        if (indexOfProcedure === -1) {
            proceduresToUpdate.push(procedure);
        } else {
            proceduresToUpdate[indexOfProcedure] = procedure;
        }
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (proceduresToUpdate.length) {
                dispatch(updateProcedures({ procedures: proceduresToUpdate }));
                proceduresToUpdate = [];
            }
        }, 2000);
    };

export const setProcedurePropAndSave =
    (id: string, path: keyof IProcedure, value: string | number | boolean | undefined) =>
    async (
        dispatch: ThunkDispatch<RootState, null, AnyAction>,
        getState: () => RootState,
    ): Promise<void> => {
        await dispatch(updateProcedureProp({ id, path, value }));
        const procedure = getState().procedures.procedures[id];
        if (procedure) dispatch(autoUpdateProcedure(procedure));
    };

const procedures = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        cleanupSelectedProcedure: (state: ProceduresState) => {
            state.selectedProcedure = undefined;
            state.isAdding = false;
        },
        toggleShowProcedureHistory: (state: ProceduresState) => {
            state.showHistory = !state.showHistory;
        },
        updateSelectedProcedureProp: (
            state: ProceduresState,
            action: PayloadAction<{ path: keyof IProcedure; value: any }>,
        ) => {
            const { path, value } = action.payload;
            if (state.selectedProcedure) (state.selectedProcedure as any)[path] = value;
        },
        updateProcedureProp: (
            state: ProceduresState,
            action: PayloadAction<{ id: string; path: keyof IProcedure; value: any }>,
        ) => {
            const { path, value, id } = action.payload;
            if (state.procedures) (state.procedures[id] as any)[path] = value ? value : undefined;
        },
        addNewProcedure: (state: ProceduresState) => {
            state.selectedProcedure = {
                id: uuid(),
                isDeleted: false,
                description: '',
                displayName: '',
                code: '',
                effectiveDate: '',
                endDate: '',
            };

            state.isAdding = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProcedureCategories.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProcedureCategories.fulfilled, (state, action) => {
                state.procedureCategories = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getProcedureCategories.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getProcedures.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProcedures.fulfilled, (state, action) => {
                state.procedures = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getProcedures.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getProcedureById.pending, (state) => {
                state.loadingSelectedProcedure = LoadingStatus.Pending;
            })
            .addCase(getProcedureById.fulfilled, (state, action) => {
                state.selectedProcedure = action.payload;
                state.loadingSelectedProcedure = LoadingStatus.Completed;
            })
            .addCase(getProcedureById.rejected, (state) => {
                state.loadingSelectedProcedure = LoadingStatus.Failed;
            })
            .addCase(addProcedure.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addProcedure.fulfilled, (state, action) => {
                const procedureId = action.payload.id;
                state.procedures[procedureId] = action.payload;
                state.selectedProcedure = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(addProcedure.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateProcedure.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateProcedure.fulfilled, (state, action) => {
                const procedureId = action.payload.id;
                state.procedures[procedureId] = action.payload;
                state.selectedProcedure = undefined;
                state.isAdding = false;
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateProcedure.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
            .addCase(updateProcedures.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateProcedures.fulfilled, (state, action) => {
                const procedures = action.payload;
                procedures.forEach((procedure) => {
                    const procedureId = procedure.id;
                    if (!isEqual(procedure, state.procedures[procedureId])) {
                        if (state.procedures[procedureId]) {
                            (state.procedures[procedureId] as IProcedure)._etag = procedure._etag;
                            (state.procedures[procedureId] as IProcedure).modifiedOn =
                                procedure.modifiedOn;
                            (state.procedures[procedureId] as IProcedure).modifiedBy =
                                procedure.modifiedBy;
                        }
                    }
                });
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateProcedures.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            });
    },
});

export const {
    cleanupSelectedProcedure,
    toggleShowProcedureHistory,
    updateSelectedProcedureProp,
    updateProcedureProp,
    addNewProcedure,
} = procedures.actions;

export default procedures.reducer;

export type ProceduresState = {
    procedures: Dictionary<IProcedure>;
    procedureCategories: IProcedureCategory[];
    selectedProcedure?: IProcedure;
    loadingSelectedProcedure: LoadingStatus;
    loadingProcedureCategories: LoadingStatus;
    loading: LoadingStatus;
    saving: LoadingStatus;
    isAdding: boolean;
    showHistory: boolean;
};
