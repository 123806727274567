import { IProcedureCategory } from 'api/models/procedure-category.model';
import IProcedure from 'api/models/procedure.model';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const proceduresState = (state: RootState) => state.procedures;

export const showProceduresHistory = createSelector(proceduresState, (state) => state.showHistory);
export const proceduresData = createSelector(proceduresState, (state) => state.procedures);
export const proceduresAsList = createSelector([proceduresData, showProceduresHistory], (proceduresData, showHistory) => {
    const list = map(proceduresData, (p) => p) as IProcedure[];
    return showHistory ? list : list.filter((p) => !p?.isDeleted);
});

export const selectProcedureCategoriesData = createSelector(proceduresState, (state) => {
    const lookup: Record<string, IProcedureCategory> = {};
    state.procedureCategories.forEach((cat) => {
        lookup[cat.id] = cat;
    });

    return lookup;
});
export const selectProcedureCategories = createSelector(proceduresState, (state) =>
    state.procedureCategories.filter((p) => !p.isDeleted),
);
export const selectProcedureCategoryOptions = createSelector(selectProcedureCategories, (categories) =>
    categories.map((c) => ({
        key: c.id,
        text: c.displayName,
    })),
);
