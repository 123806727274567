import {
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IRenderFunction,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    Spinner,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import IProcedure from 'api/models/procedure.model';
import { Section, SortableDetailsList } from 'components';
import useProcedures from 'hooks/state/useProcedures';
import { LoadingStatus } from 'interfaces/loadingStatus';
import { useEffect } from 'react';
import dateOnly, { classicDateOnly } from 'utils/dateOnly';
import ProcedurePanel from './ProcedurePanel';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { useSelector } from 'hooks';
import { selectProcedureCategoriesData } from 'state/slices/procedures/procedures.selectors';
import { useDispatch } from 'react-redux';
import { getProcedureCategories } from 'state/slices/procedures/procedures.slice';

export default function Procedures() {
    const {
        getProcedures,
        toggleShowProviderHistory,
        proceduresAsList,
        loading,
        showProceduresHistory,
        setProcedurePropAndSave,
        getProcedureById,
        loadingSelectedProcedure,
        addNewProcedure,
    } = useProcedures();
    const dispatch = useDispatch();

    const proceduresCategoriesData = useSelector(selectProcedureCategoriesData);

    useEffect(() => {
        getProcedures();
        dispatch(getProcedureCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<IProcedure>({
        fuseOptions: {
            keys: ['code', 'description', 'categoryId'],
            threshold: 0.1,
            ignoreLocation: true,
            isCaseSensitive: false,
            getFn: (procedure, path) => {
                const paths = path as string[];
                if (paths.includes('categoryId')) {
                    const categoryId = procedure[paths[0] as keyof IProcedure] as string;
                    const value = categoryId ? proceduresCategoriesData[categoryId]?.displayName : '';
                    return value;
                }

                return (procedure as any)[path as string];
            },
        },
        list: proceduresAsList,
    });

    const onProcedureClick = (item: IProcedure) => {
        if (loadingSelectedProcedure !== LoadingStatus.Pending) {
            getProcedureById(item.id);
        }
    };

    const getProcedureCategoryDisplayName = (item?: IProcedure) =>
        item?.categoryId && proceduresCategoriesData[item.categoryId]
            ? proceduresCategoriesData[item.categoryId].displayName
            : 'Unknown Category';

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <div>{defaultRender!(headerProps)}</div>
            </Sticky>
        );
    };

    const columns: IColumn[] = [
        {
            key: 'category',
            name: 'Category',
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            getValueKey: (item: IProcedure) => getProcedureCategoryDisplayName(item),
            onRender: (item: IProcedure) => <span>{getProcedureCategoryDisplayName(item)}</span>,
        },
        {
            key: 'code',
            name: 'Code',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'code',
            onRender: (item: IProcedure) => <span>{item.isDeleted ? <i>{item.code}</i> : item.code}</span>,
        },
        {
            key: 'description',
            name: 'Description',
            minWidth: 75,
            fieldName: 'description',
            onRender: (item: IProcedure) => (
                <Link title={item.description} onClick={() => onProcedureClick(item)}>
                    {item.isDeleted ? <i>{item.description}</i> : item.description}
                </Link>
            ),
        },
        {
            key: 'effectiveDate',
            name: 'Effective Date',
            minWidth: 120,
            fieldName: 'effectiveDate',
            onRender: (item: IProcedure) => <span>{item.effectiveDate ? classicDateOnly(item.effectiveDate) : ''}</span>,
        },
        {
            key: 'endDate',
            name: 'End Date',
            minWidth: 120,
            fieldName: 'endDate',
            onRender: (item: IProcedure) => <span>{item.endDate ? classicDateOnly(item.endDate) : ''}</span>,
        },
        {
            key: 'isDeleted',
            name: 'Deactivated',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'isDeleted',
            onRender: (item: IProcedure) => (
                <Stack grow horizontalAlign="center">
                    <TooltipHost content="Toggle procedure deactivated.">
                        <Toggle
                            styles={{ root: { margin: 0 } }}
                            checked={item?.isDeleted}
                            onChange={() => setProcedurePropAndSave(item.id, 'isDeleted', !item?.isDeleted)}
                        />
                    </TooltipHost>
                </Stack>
            ),
        },
    ];

    return (
        <Stack grow>
            <ProcedurePanel />
            <Section
                heading={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Text variant="large">Procedures ({search ? results.length : proceduresAsList.length})</Text>
                        <TooltipHost content="Add new procedure">
                            <IconButton
                                onClick={addNewProcedure}
                                styles={{ root: { maxHeight: 25, maxWidth: 25 } }}
                                iconProps={{ iconName: 'Add' }}
                            />
                        </TooltipHost>
                    </Stack>
                }
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 1 }}
                headingRightContent={
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow verticalAlign="end">
                        <SearchBox
                            style={{ minWidth: 235 }}
                            placeholder="Search by description, code, category"
                            autoComplete="off"
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            value={search}
                        />
                        <Toggle
                            label="Active Only"
                            inlineLabel
                            checked={!showProceduresHistory}
                            styles={{ root: { marginBottom: 3 } }}
                            onClick={toggleShowProviderHistory}
                        />
                    </Stack>
                }
                grow
            >
                {loading === 'pending' ? (
                    <div style={{ width: '100%' }}>
                        <Spinner label="Loading..." />
                    </div>
                ) : loading === 'completed' && search && !results.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No Results Found.</MessageBar>
                    </div>
                ) : loading === 'completed' && proceduresAsList.length ? (
                    <div style={{ position: 'relative', flex: 1 }}>
                        <ScrollablePane scrollbarVisibility="auto">
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                columns={columns}
                                items={results.length ? results : proceduresAsList}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                compact
                                stickyHeader
                                sortOnMount
                                sortColumns={['code']}
                                initialSortDirection={['asc']}
                            />
                        </ScrollablePane>
                    </div>
                ) : loading === 'completed' && !proceduresAsList.length ? (
                    <div style={{ width: '100%' }}>
                        <MessageBar>No procedures have been added yet.</MessageBar>
                    </div>
                ) : (
                    loading === 'failed' && (
                        <div style={{ width: '100%' }}>
                            <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
                        </div>
                    )
                )}
            </Section>
        </Stack>
    );
}
