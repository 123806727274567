import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
type Props<T> = {
    fuseOptions: Fuse.IFuseOptions<T>;
    list: T[];
};
/**
 * A hook to encapsulate the fuse.js search functionality
 * ```typescript
 * // Example
 * const { results, onSearch, search, onSearchChange } = useFuseSearch<IInterface>({
 *      fuseOptions: { keys: ['displayName'] }, // Fuse options to set/override
 *     list: items, // List[] of items that you want to search
 * });
 * <SearchBox value={search} onSearch={onSearch} onChange={onSearchChange} />
 * ```
 * ```text
 *
 * The return object:
 * - results: List of items found through results
 * - onSearch: function to use with SearchBox field onSearch prop
 * - onSearchChange: function to use with SearchBox field onChange prop
 * - search: String value to use with SearchBox value prop;
 *```
 */
export function useFuseSearch<T>({ fuseOptions, list }: Props<T>): {
    results: T[];
    onSearch: () => void;
    onSearchChange: (e?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string) => void;
    search: string;
    setSearch: (value: string) => void;
} {
    const fuse = useMemo(() => new Fuse<T>([], { threshold: 0.4, ...fuseOptions }), [list]);
    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<T[]>([]);

    const fuseSearch = () => {
        const results = fuse.search(search);
        const mappedResults = results.map((proc) => proc.item);
        setResults(mappedResults);
    };

    const _onSearch = () => fuseSearch();
    const _onSearchChange = (e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string) => {
        setSearch(value ? value : '');
    };

    useEffect(() => {
        if (!isEqual(list, results)) {
            fuse.setCollection(list);
            fuseSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return {
        results,
        onSearch: _onSearch,
        onSearchChange: _onSearchChange,
        search,
        setSearch,
    };
}
